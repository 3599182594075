.productList {
  flex: 4;
}

.productListUser {
  display: flex;
  align-items: center;
}

.productTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.productAddButton {
  width: 80px;
  border: none;
  padding: 5px;
  background-color: teal;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-size: 15px;
}

.productListImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.productListEdit {
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: #3bb077;
  color: white;
  cursor: pointer;
  margin-right: 20px;
}

.productListDelete {
  color: red !important;
  cursor: pointer;
}
