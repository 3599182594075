.newUser{
    flex: 4;
}

.newUserForm{
    display: flex;
    flex-wrap: wrap;
}

.newUserItem{
    width: 400px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-right: 20px;
}

.newUserItem > label{
    font-size: 14px;
    font-weight: 600;
    color: gray;
    margin-bottom: 10px;
}

.newUserItem > input{
    padding: 10px;
    height: 20px;
    border: 1px solid grey;
    border-radius: 5px;
}

.newUserGender > input{
  margin-top: 5px;
}

.newUserGender > label{
    margin: 10px;
    font-size: 18px;
    color: black;

}

.newUserButton{
    width: 200px;
    margin: 10px;
    padding: 7px 10px;
    background-color: darkblue;
    color: white;
    cursor: pointer;
    border: none;
    font-weight:600;
    border-radius: 10px;
}