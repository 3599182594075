.widgetlg {
  flex: 2;
  -webkit-box-shadow: 0px 0px 24px 8px rgba(0, 0, 0, 0.58);
  box-shadow: 0px 0px 24px 8px rgba(0, 0, 0, 0.58);
  padding: 20px;
}

.widgetLgTitle {
  font-size: 22px;
  font-weight: 600;
}
.widgetLgTable {
  width: 100%;
  border-spacing: 20px;
}
.widgetLgTr {
  text-align: left;
}
.widgetLgUser {
  display: flex;
  align-items: center;
  padding: 2px;
  font-weight: 600;
}
.widgetLgDate,
.widgetLgAmount {
  font-weight: 300;
}
.widgetLgImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}
.widgetLgButton {
  padding: 5px 7px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.widgetLgButton.Approval {
  background-color: green;
}

.widgetLgButton.Decline {
  background-color: red;
}
.widgetLgButton.Pending {
  background-color: peru;
}
