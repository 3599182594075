.chart {
  margin: 20px;
  padding: 20px;
  -webkit-box-shadow: 0px 0px 24px 8px rgba(0, 0, 0, 0.58);
  box-shadow: 0px 0px 24px 8px rgba(0, 0, 0, 0.58);
}

.chartTitle {
  margin-bottom: 20px;
}
